import React, { useEffect, useRef } from "react";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { ExcelRenderer } from "react-excel-renderer";
import {
  imsDataAdded,
  saveFile,
  cheeckParams,
  verifIms,
} from "../../../Redux/imsDataReduce";
import { fetchReps } from "../../../Redux/usersReduce";
import { fetchProduit, getActiveProduit } from "../../../Redux/produitReduce";
import { fetchLine, getActiveLine } from "../../../Redux/lineReduce";
import { fetchBrick, getActiveBrick } from "../../../Redux/brickReduce";
import { fetchCycle, getActiveCycle } from "../../../Redux/cycleReduce";
import { fetchMarche, getActiveMarche } from "../../../Redux/marcheReduce";
import { fetchAnneeIms } from "../../../Redux/anneeReduce";
import { fetchPersonnel, getActivePersonnel } from "../../../Redux/personnelReduce";
import { fetchSpecialite } from "../../../Redux/specialiteReduce";

function InsertImsData() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [fileName, setFileName] = React.useState("");
  const [entities, setEntities] = React.useState([]);
  const [uploadFile, setUploadFile] = React.useState("");
  const [testBtn, setTestBtn] = React.useState(false);
  const saveTableCalled = useRef(false);

  const [typeOptions] = React.useState([
    {
      value: "",
      label: "Type",
      isDisabled: true,
    },
    {
      value: "1",
      label: "Ventes IMS",
    },
    {
      value: "2",
      label: "Deploiement",
    },
    {
      value: "3",
      label: "Produit-Line",
    },
    {
      value: "4",
      label: "IMS National",
    },
    // {
    //   value: "5",
    //   label: "Line Données régionales",
    // },
  ]);
  const [typeSelect, setTypeSelect] = React.useState({
    value: 0,
    label: "Type",
  });

  const [repsOptions, setRepsOptions] = React.useState([
    {
      value: "",
      label: "Rep",
      isDisabled: true,
    },
  ]);
  const [repSelect, setRepSelect] = React.useState({
    value: 0,
    label: "Rep",
  });

  const [productOptions, setProductOptions] = React.useState([
    {
      value: "",
      label: "Produit",
      isDisabled: true,
    },
  ]);
  const [productSelect, setProductSelect] = React.useState({
    value: 0,
    label: "Produit",
  });

  const [linesOptions, setLinesOptions] = React.useState([
    {
      value: "",
      label: "Line",
      isDisabled: true,
    },
  ]);
  const [lineSelect, setLineSelect] = React.useState({
    value: 0,
    label: "Line",
  });

  const [bricksOptions, setBricksOptions] = React.useState([
    {
      value: "",
      label: "Brick",
      isDisabled: true,
    },
  ]);
  const [brickSelect, setBrickSelect] = React.useState({
    value: 0,
    label: "Brick",
  });

  const [marchesOptions, setMarchesOptions] = React.useState([
    {
      value: "",
      label: "Marché",
      isDisabled: true,
    },
  ]);
  const [marcheSelect, setMarcheSelect] = React.useState({
    value: 0,
    label: "Marché",
  });

  const [cyclesOptions, setCyclesOptions] = React.useState([
    {
      value: "",
      label: "Cycle",
      isDisabled: true,
    },
  ]);
  const [cycleSelect, setCycleSelect] = React.useState({
    value: 0,
    label: "Cycle",
  });

  const [mois, setMois] = React.useState({
    value: "",
    label: "Mois",
    isDisabled: true,
  });
  const [optionsMois] = React.useState([
    {
      value: "",
      label: "Mois",
      isDisabled: true,
    },
    {
      value: 1,
      label: "Janvier",
    },
    {
      value: 2,
      label: "Février",
    },
    {
      value: 3,
      label: "Mars",
    },
    {
      value: 4,
      label: "Avril",
    },
    {
      value: 5,
      label: "Mai",
    },
    {
      value: 6,
      label: "Juin",
    },
    {
      value: 7,
      label: "Juillet",
    },
    {
      value: 8,
      label: "Août",
    },
    {
      value: 9,
      label: "Septembre",
    },
    {
      value: 10,
      label: "Octobre",
    },
    {
      value: 11,
      label: "Novembre",
    },
    {
      value: 12,
      label: "Décembre",
    },
  ]);

  const [anneeOptions, setAnneeOptions] = React.useState([
    {
      value: "",
      label: "Année",
      isDisabled: true,
    },
  ]);
  const [anneeSelect, setAnneeSelect] = React.useState({
    value: "",
    label: "Année",
  });

  const [arrayUsers, setArrayUsers] = React.useState([]);
  const [arrayProduits, setArrayProduits] = React.useState([]);
  const [arrayCodesProduits, setArrayCodesProduits] = React.useState([]);
  const [arrayBrick, setArrayBrick] = React.useState([]);
  const [arraySp, setArraySp] = React.useState([]);
  const [arrayPers, setArrayPers] = React.useState([]);
  const [arrayCycle, setArrayCycle] = React.useState([]);
  const [arrayMarche, setArrayMarche] = React.useState([]);

  const [arrayLine, setArrayLine] = React.useState([]);

  const [arrayIdProduits, setArrayIdProduits] = React.useState([]);
  const [arrayIdBrick, setArrayIdBrick] = React.useState([]);
  const [arrayIdSp, setArrayIdSp] = React.useState([]);
  const [arrayIdMarche, setArrayIdMarche] = React.useState([]);
  const [arrayIdCycle, setArrayIdCycle] = React.useState([]);
  const [arrayIdPers, setArrayIdPers] = React.useState([]);
  const [arrayIdLine, setArrayIdLine] = React.useState([]);

  const [isHidden, setIsHidden] = React.useState(false);

  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    if (
      fileObj.name.slice(-5) === ".xlsx" ||
      fileObj.name.slice(-4) === ".xls" ||
      fileObj.name.slice(-4) === ".csv"
    ) {
      setUploadFile(fileObj);
      var str = fileObj.name.replace(/[^a-zA-Z0-9,;\-.!? ]/g, "");
      setFileName(str);
    } else {
      notify(2, "Votre fichier doit être de type CSV !");
    }
  };

  const showTable = React.useCallback(
    async (mois, annee, anneeLabel) => {
      try {
        if (fileName !== "") {
          document.getElementById("loaderTable").classList.remove("hidden");
          setIsHidden(true);
          ExcelRenderer(uploadFile, (err, resp) => {
            if (err) {
              console.log("error", err);
            } else {
              verifLine(resp.rows, mois, annee, anneeLabel);
            }
          });
        } else {
          document.getElementById("loaderTable").classList.add("hidden");
          setIsHidden(false);
          notify(2, "Vérifiez vos données");
        }
      } catch (error) {
        setIsHidden(false);
        console.error(error);
      }
    },
    [fileName, uploadFile]
  );

  const verifLine = (array, mois, annee, anneeLabel) => {
    try {
      let newArray = array.filter((arr) => arr.length > 0);
      let dataArray = [];
      let elementsCheck = [];
      let testHeader = null;
      if (typeSelect.value == 1) {
        elementsCheck = [
          "Cycle",
          "TypeMesure",
          "BRICKS",
          "MARKET",
          "Mois",
          "Trimestre",
          "YTD",
          "MAT",
          "AnneeMois",
          "Product",
          "Mesure",
          "MesureN_1",
          "MesureMarche",
          "MesureN_1Marche",
          "MesureNatProduit",
          "MesureN_1NatProduit",
          "MesureNatMarche",
          "MesureN_1NatMarche",
        ];
        testHeader = elementsCheck.every((element) =>
          newArray[0].includes(element)
        );
      } else if (typeSelect.value == 2) {
        elementsCheck = [
          "Line",
          "TerritoireFLM",
          "FLM",
          "TerritoireAM",
          "AM",
          "Territoire",
          "Rep",
          "CodeBrick",
          "Brick",
          "DateDebut",
          "DateFin",
        ];
        testHeader = elementsCheck.every((element) =>
          newArray[0].includes(element)
        );
      } else if (typeSelect.value == 3) {
        elementsCheck = ["Line", "Produit"];
        testHeader = elementsCheck.every((element) =>
          newArray[0].includes(element)
        );
      } else if (typeSelect.value == 4) {
        elementsCheck = [
          "ATC2",
          "ATC3",
          "ATC4",
          "Labo",
          "Produit",
          "PFC",
          "Presentation",
          "TypeProduit",
          "Origine",
          "TypeMesure",
          "AnneeMois",
          "Vente",
        ];
        testHeader = elementsCheck.every((element) =>
          newArray[0].includes(element)
        );
      } else {
        elementsCheck = ["Line", "Territoire", "LineHybride"];
        testHeader = elementsCheck.every((element) =>
          newArray[0].includes(element)
        );
      }

      if (testHeader) {
        newArray.slice(1).forEach((element) => {
          let objData = {};
          if (element.length > 0) {
            if (typeSelect.value == 1) {
              objData = {
                cycle: element[0],
                typeMesure: element[1],
                brick: element[2],
                market: element[3],
                mois: element[4],
                trimstre: element[5],
                ytd: element[6],
                mat: element[7],
                anneeMois: element[8],
                produit: element[9],
                mesure: element[10],
                mesureN_1: element[11],
                mesureMarche: element[12],
                mesureN_1Marche: element[13],
                mesureNatProduit: element[14],
                mesureN_1NatProduit: element[15],
                mesureNatMarche: element[16],
                mesureN_1NatMarche: element[17],
              };
            } else if (typeSelect.value == 2) {
              const annee = element[9].toString().substring(0, 4);
              const mois = element[9].toString().substring(4, 6);
              const jour = element[9].toString().substring(6, 8);
              const dateDebut = `${annee}-${mois}-${jour}`;
              const annee1 = element[10].toString().substring(0, 4);
              const mois1 = element[10].toString().substring(4, 6);
              const jour1 = element[10].toString().substring(6, 8);
              const dateFin = `${annee1}-${mois1}-${jour1}`;
              objData = {
                linee: element[0],
                territoireFLM: element[1],
                flm: element[2],
                territoireAm: element[3],
                am: element[4],
                territoire: element[5],
                rep: element[6],
                codeBrick: element[7],
                brick: element[8],
                dateDebut: dateDebut,
                dateFin: dateFin,
              };
            } else if (typeSelect.value == 3) {
              objData = {
                line: element[0],
                produit: element[1],
              };
            } else if (typeSelect.value == 4) {
              objData = {
                atc2: element[0] || "",
                atc3: element[1] || "",
                atc4: element[2] || "",
                labo: element[3] || "",
                produit: element[4] || "",
                pfc: element[5] || "",
                presentation: element[6] || "",
                typeProduit: element[7] || "",
                origine: element[8] || "",
                typeMesure: element[9] || "",
                anneeMois: element[10] || "",
                vente: element[11] || "",
              };
            } else {
              objData = {
                line: element[0],
                territoire: element[1],
                lineHybride: element[2],
              };
            }
          }
          dataArray.push(objData);
        });
        let newDataArray = [];
        if (typeSelect.value == 4 || typeSelect.value == 1) {
          const month = mois.toString().padStart(2, "0");
          newDataArray = dataArray.filter(
            (e) =>
              e.anneeMois?.toString().startsWith(anneeLabel) &&
              e.anneeMois?.toString().slice(-2) == month
          );
        } else {
          newDataArray = dataArray;
        }
       
        if (newDataArray.length == 0) {
          notify(2, "Vérifier votre fichier !");
          document.getElementById("loaderTable").classList.add("hidden");
          setIsHidden(false);
          return;
        }

        dispatch(
          verifIms({ type: typeSelect.value, annee: annee, mois: mois })
        ).then((res) => {
          if (res.payload) {
            if (typeSelect.value == 1) {
              processJsonData(newDataArray);
              //document.getElementById("loaderTable").classList.add("hidden");
            } else {
              dispatch(
                cheeckParams({ data: newDataArray, type: typeSelect.value })
              ).then((rows) => {
                setEntities(rows.payload);
                //document.getElementById("loaderTable").classList.add("hidden");
              });
            }
          } else {
            document.getElementById("loaderTable").classList.add("hidden");
            notify(2, res.payload.msg);
          }
        });
      } else {
        document.getElementById("loaderTable").classList.add("hidden");
        setIsHidden(false);
        notify(2, "Vérifier votre fichier !");
      }
    } catch (error) {
      console.error(error);
      setIsHidden(false);
      document.getElementById("loaderTable").classList.add("hidden");
    }
  };

  const saveTable = React.useCallback(
    async (entities, uploadFile, type, moisVal, anneeVal) => {
      if (saveTableCalled.current) return;
      saveTableCalled.current = true;
      let verif = true;

      let verifMois = true;
      if (type == 1 && moisVal == "") {
        verifMois = false;
      }

      //Keys Produits
      let keys = entities
        .filter(
          (element) => element.id_produit === "" || element.id_produit === null
        )
        .map((element) => element.index);
      let keysProd = keys.join("-");

      //Keys Cycles
      let keys2 = entities
        .filter((element) => element.id_cycle === "")
        .map((element) => element.index);
      let keysCycle = keys2.join("-");

      //Keys Bricks
      let keys3 = entities
        .filter((element) => element.id_brick === "")
        .map((element) => element.index);
      let keysBrick = keys3.join("-");

      //Keys Market
      let keys4 = entities
        .filter(
          (element) => element.id_market === "" || element.id_market === null
        )
        .map((element) => element.index);
      let keysMarket = keys4.join("-");

      // Keys Rep
      let keys5 = entities
        .filter((element) => element.id_rep === "")
        .map((element) => element.index);
      let keysRep = keys5.join("-");

      // Keys Line
      let keys6 = entities
        .filter((element) => element.id_line === "")
        .map((element) => element.index);
      let keysLine = keys6.join("-");

      let checks = [];

      if (type == 1) {
        checks = [
          {
            keys: keys,
            message: "Veuillez choisir les produits des lignes " + keysProd,
          },
          {
            keys: keys2,
            message: "Veuillez choisir les cycles des lignes " + keysCycle,
          },
          {
            keys: keys3,
            message: "Veuillez choisir les bricks des lignes " + keysBrick,
          },
          {
            keys: keys4,
            message: "Veuillez choisir les marchés des lignes " + keysMarket,
          },
        ];
      } else if (type == 2) {
        checks = [
          {
            keys: keys5,
            message: "Veuillez choisir les reps des lignes " + keysRep,
          },

          {
            keys: keys3,
            message: "Veuillez choisir les bricks des lignes " + keysBrick,
          },
        ];
      } else if (type == 4) {
        checks = [
          {
            keys: keys,
            message: "Veuillez choisir les produits des lignes " + keysProd,
          },
        ];
      } else {
        checks = [
          {
            keys: keys6,
            message: "Veuillez choisir les lines des lignes " + keysLine,
          },
          {
            keys: keys,
            message: "Veuillez choisir les produits des lignes " + keysProd,
          },
        ];
      }
      checks.forEach((check) => {
        if (check.keys.length > 0) {
          verif = false;
          notify(2, check.message);
        }
      });

      if (
        uploadFile &&
        entities.length > 0 &&
        verif &&
        verifMois &&
        anneeVal !== ""
      ) {
        document.getElementById("loaderTable").classList.remove("hidden");
        const dataArray = new FormData();
        dataArray.append("file", uploadFile);
        dataArray.append("name", uploadFile.name);
        dispatch(saveFile(dataArray)).then((e) => {
          setTestBtn(true);
          let filename = e.payload.filename;
          dispatch(
            imsDataAdded({
              file: filename,
              namefile: uploadFile.name,
              lignes: entities,
              type: type,
              mois: moisVal,
              annee: anneeVal,
            })
          ).then((e) => {
            if (e.payload) {
              document.getElementById("loaderTable").classList.add("hidden");
              notify(1, "Insertion avec succés !");
              setTimeout(() => {
                listImsData();
              }, 1300);
            } else {
              document.getElementById("loaderTable").classList.add("hidden");
              saveTableCalled.current = false;
              setTestBtn(false);
              notify(2, "Problème de connexion !");
            }
          });
        });
      } else {
        document.getElementById("loaderTable").classList.add("hidden");
        saveTableCalled.current = false;
        notify(2, "Vérifier vos données !");
      }
    },
    []
  );

  const processJsonData = (jsondata) => {
    const finalData = jsondata.map((item) => {
      if (item.contact) {
        const index = arrayPers.indexOf(
          item.contact.toLowerCase().toString().trim()
        );
        if (index >= 0) {
          item.id_contact = arrayIdPers[index];
          item.contact = arrayPers[index].toLowerCase();
        } else {
          item.id_contact = null;
        }
      }

      if (item.market) {
        const index = arrayMarche.indexOf(
          item.market.toLowerCase().toString().trim()
        );
        if (index >= 0) {
          item.id_market = arrayIdMarche[index];
          item.market = arrayMarche[index].toLowerCase();
        } else {
          item.id_market = null;
        }
      }

      if (item.specialite) {
        const index = arraySp.indexOf(
          item.specialite.toLowerCase().toString().trim()
        );
        if (index >= 0) {
          item.id_specialite = arrayIdSp[index];
          item.specialite = arraySp[index].toLowerCase();
        } else {
          item.id_specialite = null;
        }
      }

      if (item.brick) {
        const index = arrayBrick.indexOf(
          item.brick.toLowerCase().toString().trim()
        );
        if (index >= 0) {
          item.id_brick = arrayIdBrick[index];
          item.brick = arrayBrick[index].toLowerCase();
        } else {
          item.id_brick = null;
        }
      }

      if (item.cycle) {
        const index = arrayCycle.indexOf(
          item.cycle.toLowerCase().toString().trim()
        );
        if (index >= 0) {
          item.id_cycle = arrayIdCycle[index];
          item.cycle = arrayCycle[index].toLowerCase();
        } else {
          item.id_cycle = null;
        }
      }

      if (item.produit) {
        const index = arrayProduits.indexOf(
          item.produit.toLowerCase().toString().trim()
        );
        if (index >= 0) {
          item.id_produit = arrayIdProduits[index];
          item.produit = arrayProduits[index].toLowerCase();
        } else {
          item.id_produit = null;
        }
      }

      return item;
    });

    if (finalData.length > 0) {
      let arrayFinal = finalData.map((value, index) => {
        return { ...value, index: index + 1 };
      });
      setEntities(arrayFinal);
      //document.getElementById("loaderTable").classList.add("hidden");
    } else {
      document.getElementById("loaderTable").classList.add("hidden");
      notify(2, "Vérifier votre fichier !");
    }
  };

  async function getReps() {
    let object = {};
    let arrayOption = [];
    let users = await dispatch(fetchReps());
    let entities = users.payload;
    arrayOption.push({ value: 0, label: "Rep", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object[e.id] = { value: e.id, label: e.nom };
    });
    setRepSelect(object);
    setRepsOptions(arrayOption);
  }

  async function getProduits() {
    let object2 = {};
    let arrayOption = [];
    let produits = await dispatch(getActiveProduit());
    let entities = produits.payload;
    arrayOption.push({ value: 0, label: "Produit", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object2[e.id] = { value: e.id, label: e.nom };
    });
    setProductSelect(object2);
    setProductOptions(arrayOption);
    setArrayProduits(
      entities.map((item) => item.nom && item.nom.toLowerCase().trim())
    );
    setArrayIdProduits(entities.map((item) => item.id));
  }

  async function getAnnees() {
    let arrayOption = [];
    let annee = await dispatch(fetchAnneeIms());
    let entities = annee.payload;
    arrayOption.push({ value: 0, label: "Année", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.annee });
    });
    setAnneeOptions(arrayOption);
  }

  async function getCycles() {
    let objCycle = {};
    let arrayOption = [];
    let cycle = await dispatch(getActiveCycle());
    let entities = cycle.payload.cycles;
    arrayOption.push({ value: 0, label: "Cycle", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id_cycle, label: e.designation });
      objCycle[e.id] = { value: e.id_cycle, label: e.designation };
    });
    setCycleSelect(objCycle);
    setCyclesOptions(arrayOption);

    setArrayCycle(
      entities.map(
        (item) => item.designation && item.designation.toLowerCase().trim()
      )
    );
    setArrayIdCycle(entities.map((item) => item.id_cycle));
  }

  async function getMarches() {
    let objMarche = {};
    let arrayOption = [];
    let produits = await dispatch(getActiveMarche());
    let entities = produits.payload;
    arrayOption.push({ value: 0, label: "Marché", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.designation });
      objMarche[e.id] = { value: e.id, label: e.designation };
    });
    setMarcheSelect(objMarche);
    setMarchesOptions(arrayOption);

    setArrayMarche(
      entities.map(
        (item) => item.designation && item.designation.toLowerCase().trim()
      )
    );
    setArrayIdMarche(entities.map((item) => item.id));
  }

  async function getLines() {
    let object3 = {};
    let arrayOption = [];
    let lines = await dispatch(getActiveLine());
    let entities = lines.payload;
    arrayOption.push({ value: 0, label: "Line", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object3[e.id] = { value: e.id, label: e.nom };
    });
    setLineSelect(object3);
    setLinesOptions(arrayOption);

    setArrayLine(
      entities.map((item) => item.nom && item.nom.toLowerCase().trim())
    );
    setArrayIdLine(entities.map((item) => item.id));
  }

  async function getBricks() {
    let object4 = {};
    let arrayOption = [];
    let lines = await dispatch(getActiveBrick());
    let entities = lines.payload;
    arrayOption.push({ value: 0, label: "Brick", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object4[e.id] = { value: e.id, label: e.nom };
    });
    setBrickSelect(object4);
    setBricksOptions(arrayOption);
    setArrayBrick(
      entities.map((item) => item.nom && item.nom.toLowerCase().trim())
    );
    setArrayIdBrick(entities.map((item) => item.id));
  }

  async function getData() {
    const personnels = await dispatch(getActivePersonnel());
    const entities = personnels.payload;
    setArrayIdPers(entities.map((item) => item.id));
    setArrayPers(
      entities.map(
        (item) => item.nomPrenom && item.nomPrenom.toLowerCase().trim()
      )
    );

    const sp = await dispatch(fetchSpecialite());
    const res = sp.payload;

    setArrayIdSp(res.map((item) => item.id));
    setArraySp(res.map((item) => item.nom && item.nom.toLowerCase().trim()));
  }

  useEffect(() => {
    getReps();
    getProduits();
    getLines();
    getBricks();
    getCycles();
    getMarches();
    getAnnees();
    getData();
  }, [location.id, dispatch]);

  function listImsData() {
    navigate.push("/ListImsData");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listImsData}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">{"Ajouter données IMS"}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="6">
                          <Form.Group id="typeClass">
                            <label>Type* </label>
                            <Select
                              placeholder="Line"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={typeSelect}
                              onChange={(value) => {
                                setTypeSelect(value);
                              }}
                              options={typeOptions}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Fichier * </label>
                            <Form.Control
                              accept=".xls, .xlsx, .csv"
                              placeholder="Image"
                              type="file"
                              onChange={(value) => {
                                fileHandler(value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="anneeClass">
                            <label> Année * </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={anneeSelect}
                              onChange={(value) => {
                                setAnneeSelect(value);
                              }}
                              options={anneeOptions}
                            />
                          </Form.Group>
                        </Col>
                        {typeSelect.value == 1 || typeSelect.value == 4 ? (
                          <Col className="pl-1" md="6">
                            <Form.Group id="moisClass">
                              <label> Mois * </label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={mois}
                                onChange={(value) => {
                                  setMois(value);
                                }}
                                options={optionsMois}
                              />
                            </Form.Group>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          onClick={() =>
                            saveTable(
                              entities,
                              uploadFile,
                              typeSelect.value,
                              mois.value,
                              anneeSelect.value
                            )
                          }
                          style={{
                            display: entities.length === 0 ? "none" : "",
                          }}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <br></br>
                      <br></br>
                      <div className="table-responsive">
                        <table
                          className={`table table-striped ${
                            typeSelect.value == 1 ? "small-table" : ""
                          }`}
                          style={{
                            display: entities.length > 0 ? "none" : "",
                          }}
                        >
                          <thead>
                            {typeSelect.value == 1 ? (
                              <tr>
                                <th> Product </th>
                                <th> Cycle </th>
                                <th> TypeMesure </th>
                                <th> BRICKS </th>
                                <th> MARKET </th>
                                <th> Mois </th>
                                <th> Trimestre </th>
                                <th> YTD </th>
                                <th> MAT </th>
                                <th> AnneeMois </th>
                                <th> Mesure </th>
                                <th> MesureN_1</th>
                                <th> MesureMarche </th>
                                <th> MesureN_1Marche </th>
                                <th> MesureNatProduit </th>
                                <th> MesureN_1NatProduit </th>
                                <th> MesureNatMarche </th>
                                <th> MesureN_1NatMarche </th>
                              </tr>
                            ) : typeSelect.value == 2 ? (
                              <tr>
                                <th> Line </th>
                                <th> TerritoireFLM </th>
                                <th> FLM </th>
                                <th> TerritoireAM</th>
                                <th> AM </th>
                                <th> Territoire </th>
                                <th> Rep </th>
                                <th> CodeBrick </th>
                                <th> Brick </th>
                                <th> DateDebut </th>
                                <th> DateFin </th>
                              </tr>
                            ) : typeSelect.value == 3 ? (
                              <tr>
                                <th> Line </th>
                                <th> Produit </th>
                              </tr>
                            ) : typeSelect.value == 4 ? (
                              <tr>
                                <th> ATC2 </th>
                                <th> ATC3 </th>
                                <th> ATC4 </th>
                                <th> Labo </th>
                                <th> Produit </th>
                                <th> PFC </th>
                                <th> Presentation </th>
                                <th> TypeProduit </th>
                                <th> Origine </th>
                                <th> TypeMesure </th>
                                <th> AnneeMois </th>
                                <th> Vente </th>
                              </tr>
                            ) : typeSelect.value == 5 ? (
                              <tr>
                                <th> Line </th>
                                <th> Territoire </th>
                                <th> LineHybride </th>
                              </tr>
                            ) : (
                              ""
                            )}
                          </thead>
                        </table>
                      </div>
                      <Button
                        className="btn-wd btn-outline mr-1 float-right extraction"
                        id="imsExt"
                        type="button"
                        variant="info"
                        style={{
                          display: entities.length === 0 ? "block" : "none",
                          float: "right",
                          display: isHidden ? "none" : "block",
                        }}
                        onClick={() => {
                          const testType = typeSelect.value;
                          const testMois = mois.value !== "";
                          const testAnnee = anneeSelect.value !== "";
                          if (
                            ((testType == 1 || testType == 4) &&
                              testMois &&
                              testAnnee) ||
                            (testType == 2 && testAnnee) ||
                            (testType == 3 && testAnnee)
                          ) {
                            showTable(
                              mois.value,
                              anneeSelect.value,
                              anneeSelect.label
                            );
                          } else {
                            notify(
                              2,
                              typeSelect.value == 1
                                ? "Veuillez choisir l'année et le mois"
                                : "Veuillez choisir l'année"
                            );
                          }
                        }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-cogs"></i>
                        </span>
                        Extraction
                      </Button>
                      <br></br>
                      <div
                        className={
                          typeSelect.value == 1 || typeSelect.value == 4
                            ? "table-responsive"
                            : ""
                        }
                      >
                        <table
                          className="table table-striped"
                          style={{
                            display: entities.length === 0 ? "none" : "",
                          }}
                        >
                          <thead>
                            {typeSelect.value == 1 ? (
                              <tr>
                                <th></th>
                                <th> Product </th>
                                <th> Cycle </th>
                                <th> TypeMesure </th>
                                <th> BRICKS </th>
                                <th> MARKET </th>
                                <th> Mois </th>
                                <th> Trimestre </th>
                                <th> YTD </th>
                                <th> MAT </th>
                                <th> AnneeMois </th>
                                <th> Mesure </th>
                                <th> MesureN_1</th>
                                <th> MesureMarche </th>
                                <th> MesureN_1Marche </th>
                                <th> MesureNatProduit </th>
                                <th> MesureN_1NatProduit </th>
                                <th> MesureNatMarche </th>
                                <th> MesureN_1NatMarche </th>
                              </tr>
                            ) : typeSelect.value == 2 ? (
                              <tr>
                                <th></th>
                                <th> Line </th>
                                <th> TerritoireFLM </th>
                                <th> FLM </th>
                                <th> TerritoireAM</th>
                                <th> AM </th>
                                <th> Territoire </th>
                                <th> Rep </th>
                                <th> CodeBrick </th>
                                <th> Brick </th>
                                <th> DateDebut </th>
                                <th> DateFin </th>
                              </tr>
                            ) : typeSelect.value == 3 ? (
                              <tr>
                                <th></th>
                                <th> Line </th>
                                <th> Produit </th>
                              </tr>
                            ) : typeSelect.value == 4 ? (
                              <tr>
                                <th> ATC2 </th>
                                <th> ATC3 </th>
                                <th> ATC4 </th>
                                <th> Labo </th>
                                <th> Produit </th>
                                <th> PFC </th>
                                <th> Presentation </th>
                                <th> Type Produit </th>
                                <th> Origine </th>
                                <th> TypeMesure </th>
                                <th> Annee Mois </th>
                                <th> Vente </th>
                              </tr>
                            ) : typeSelect.value == 5 ? (
                              <tr>
                                <th> Line </th>
                                <th> Territoire </th>
                                <th> LineHybride </th>
                              </tr>
                            ) : (
                              ""
                            )}
                          </thead>
                          <tbody>
                            {entities.map((val, key) => {
                              return (
                                <tr key={key}>
                                  {typeSelect.value == 1 ? (
                                    <>
                                      <td>{val.index}</td>
                                      <td
                                        className={
                                          val.id_produit === null
                                            ? "td-select"
                                            : ""
                                        }
                                        style={{
                                          backgroundColor:
                                            val.id_produit == null
                                              ? "#e74a4a9e"
                                              : "transparent",
                                        }}
                                      >
                                        {val.id_produit !== null ? (
                                          val.produit
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p style={{ fontSize: "12px" }}>
                                              {val.produit}
                                            </p>
                                          </div>
                                        )}
                                      </td>
                                      <td
                                        className={
                                          val.testCycle !== undefined
                                            ? "td-select"
                                            : ""
                                        }
                                      >
                                        {val.testCycle === undefined ? (
                                          <>{val.cycle}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.cycle}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={cycleSelect[val.id_cycle]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_cycle: value.value,
                                                };
                                                setEntities(list);
                                                setCycleSelect(value);
                                              }}
                                              options={cyclesOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.typeMesure}</td>
                                      <td
                                        className={
                                          val.testBrick !== undefined
                                            ? "td-select"
                                            : ""
                                        }
                                      >
                                        {val.testBrick === undefined ? (
                                          <>{val.brick}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.brick}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={brickSelect[val.id_brick]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_brick: value.value,
                                                };
                                                setEntities(list);
                                                setBrickSelect(value);
                                              }}
                                              options={bricksOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td
                                        className={
                                          val.id_market == null
                                            ? "td-select"
                                            : ""
                                        }
                                        style={{
                                          backgroundColor:
                                            val.id_market == null
                                              ? "#e74a4a9e"
                                              : "transparent",
                                        }}
                                      >
                                        {val.id_market !== null ? (
                                          val.market
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p style={{ fontSize: "12px" }}>
                                              {val.market}
                                            </p>
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.mois}</td>
                                      <td>{val.trimstre}</td>
                                      <td>{val.ytd}</td>
                                      <td>{val.mat}</td>
                                      <td>{val.anneeMois}</td>
                                      <td>{val.mesure}</td>
                                      <td>{val.mesureN_1}</td>
                                      <td>{val.mesureMarche}</td>
                                      <td>{val.mesureN_1Marche}</td>
                                      <td>{val.mesureNatProduit}</td>
                                      <td>{val.mesureN_1NatProduit}</td>
                                      <td>{val.mesureNatMarche}</td>
                                      <td>{val.mesureN_1NatMarche}</td>
                                    </>
                                  ) : typeSelect.value == 2 ? (
                                    <>
                                      <td> {val.index} </td>
                                      <td>{val.linee}</td>
                                      <td>{val.territoireFLM}</td>
                                      <td>{val.flm}</td>
                                      <td>{val.territoireAm}</td>
                                      <td>{val.am}</td>
                                      <td>{val.territoire}</td>
                                      <td>
                                        {val.testRep === undefined ? (
                                          <>{val.rep}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.rep}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={repSelect[val.id_rep]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_rep: value.value,
                                                };
                                                setEntities(list);
                                                setRepSelect(value);
                                              }}
                                              options={repsOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.codeBrick}</td>
                                      <td>
                                        {val.testBrick === undefined ? (
                                          <>{val.brick}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.brick}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={brickSelect[val.id_brick]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_brick: value.value,
                                                };
                                                setEntities(list);
                                                setBrickSelect(value);
                                              }}
                                              options={bricksOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.dateDebut}</td>
                                      <td>{val.dateFin}</td>
                                    </>
                                  ) : typeSelect.value == 3 ? (
                                    <>
                                      <td>{val.index} </td>
                                      <td>
                                        {val.testLine === undefined ? (
                                          <>{val.line}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.line}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={lineSelect[val.id_line]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_line: value.value,
                                                };
                                                setEntities(list);
                                                setLineSelect(value);
                                              }}
                                              options={linesOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        {val.testProduit === undefined ? (
                                          val.produit
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.produit}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={
                                                productSelect[val.id_produit]
                                              }
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_produit: value.value,
                                                };
                                                setEntities(list);
                                                setProductSelect(value);
                                              }}
                                              options={productOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                    </>
                                  ) : typeSelect.value == 4 ? (
                                    <>
                                      <td>{val.atc2}</td>
                                      <td>{val.atc3}</td>
                                      <td>{val.atc4}</td>
                                      <td>{val.labo}</td>
                                      <td
                                        className={
                                          val.testProduit !== undefined
                                            ? "td-select"
                                            : ""
                                        }
                                      >
                                        {val.testProduit === undefined ? (
                                          val.produit
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.produit}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={
                                                productSelect[val.id_produit]
                                              }
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_produit: value.value,
                                                };
                                                setEntities(list);
                                                setProductSelect(value);
                                              }}
                                              options={productOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.pfc}</td>
                                      <td>{val.presentation}</td>
                                      <td>{val.typeProduit}</td>
                                      <td>{val.origine}</td>
                                      <td>{val.typeMesure}</td>
                                      <td>{val.AnneeMois}</td>
                                      <td>{val.vente}</td>
                                    </>
                                  ) : (
                                    <>
                                      <td>{val.line}</td>
                                      <td>{val.territoire}</td>
                                      <td>{val.lineHybride}</td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <br></br>
                      <hr></hr>

                      <div className="text-center">
                        <img
                          id="loaderTable"
                          className="hidden"
                          src={require("../../../assets/img/loader.gif")}
                          alt="loader"
                          style={{
                            display: entities.length > 0 ? "none" : "",
                          }}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default InsertImsData;
