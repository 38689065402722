import { Button, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getdata } from "../../../Redux/dataReduce";

// core components
function DetailsOceData() {
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);

  var id = location.id;

  const getOce = useCallback(async () => {
    document.getElementById("loaderTable").classList.remove("hidden");
    var res = await dispatch(getdata(id));
    var entities = res.payload.data;
    setData(entities);
    document.getElementById("loaderTable").classList.add("hidden");
  }, [dispatch, id]);

  useEffect(() => {
    getOce();
  }, [getOce]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container className="container-details">
            <Card>
              <Card.Header className="background-white">
                <Col md="12">
                  <Button
                    id="returnBtn"
                    className="btn-wd  mr-1 float-left"
                    type="button"
                    variant="info"
                    onClick={(event) => {
                      navigate.goBack();
                    }}
                  >
                    <span id="returnSpan" className="btn-label">
                      <i className="fas fa-list"></i>
                    </span>
                    Retour à la liste
                  </Button>
                </Col>
                <br clear="all"></br>
                <Card.Title
                  className="background-white"
                  as="h3"
                  style={{ textAlign: "center" }}
                >
                  {"Détails OCE"}
                </Card.Title>
              </Card.Header>
              <Card>
                <Card.Body>
                  <div className="text-center">
                    <img
                      id="loaderTable"
                      className="hidden"
                      src={require("../../../assets/img/loader.gif")}
                      alt="loader"
                    />
                  </div>
                  <div
                    className="scroll-sync"
                    onScroll={(e) => {
                      document.querySelector(".table-scroll").scrollLeft =
                        e.target.scrollLeft;
                    }}
                  >
                    <div style={{ width: "2000px" }}></div>
                  </div>
                  <br></br>
                  <div className="table-scroll">
                    <table className={`table table-striped`}>
                      <thead className="fixed-header">
                        <tr>
                          <th>CodeOneKeyEtablissement</th>
                          <th>Etablissement</th>
                          <th>Service</th>
                          <th>CodeOneKey</th>
                          <th>Contact</th>
                          <th>Specialite1</th>
                          <th>Statut</th>
                          <th>DatePlanifiee</th>
                          <th>DateVisite</th>
                          <th>DateEnvoi</th>
                          <th>CodeBrick</th>
                          <th>Canal</th>
                          <th>CodeVisite</th>
                          <th>Adresse</th>
                          <th>Rep</th>
                          <th>Territoire</th>
                          <th>Commentaires</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((val, key) => {
                          return (
                            <tr key={key}>
                              <>
                                <td>{val.code_onekey_etabliss}</td>
                                <td>{val.etablissement}</td>
                                <td>{val.service}</td>
                                <td>{val.code_onekey}</td>
                                <td>
                                  {val.personnels
                                    ? val.personnels.nomPrenom
                                    : ""}
                                </td>
                                <td>
                                  {val.specialites ? val.specialites.nom : ""}
                                </td>
                                <td>{val.statut}</td>
                                <td>{val.date_planifiee}</td>
                                <td>{val.date_visite}</td>
                                <td>{val.date_envoi}</td>
                                <td>{val.code_brick}</td>
                                <td>{val.canal}</td>
                                <td>{val.code_visite}</td>
                                <td>{val.adresse}</td>
                                <td>{val.users ? val.users.nom : ""}</td>
                                <td>{val.territoire}</td>
                                <td>{val.commentaire}</td>
                                {/* <td>
                                  {val.cycles ? val.cycles.designation : ""}
                                </td>
                                <td>{val.panel_segment}</td>
                                <td>{val.panel_specialite1}</td>
                                <td>{val.panel_specialite2}</td>
                                <td>{val.panel_service}</td>
                                <td>{val.bricks ? val.bricks.nom : ""}</td> */}
                              </>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Card>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailsOceData;
