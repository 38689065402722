import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchPersonnel = createAsyncThunk("personnel/fetchPersonnel", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "personnel/allPersonnel", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const personnel = await response.json();
  return personnel;
});


export const getActivePersonnel = createAsyncThunk("personnel/getActivePersonnel", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "personnel/getActivePersonnel", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },

  });
  const personnel = await response.json();
  return personnel;
});

export const personnelAdded = createAsyncThunk("personnel/addPersonnel", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "personnel/addPersonnel", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const personnel = await response.json();
    return personnel;
  });

  export const getPersonnelsBySpAndGv = createAsyncThunk("personnel/getPersonnelsByIdSpAndIdGv", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "personnel/getPersonnelsByIdSpAndIdGv", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const personnel = await response.json();
    return personnel;
  });


export const personnelGetById = createAsyncThunk("personnel/personnelGetById", async (id1) => {
  const  id  = id1;
  const response = await fetch(Configuration.BACK_BASEURL + "personnel/getPersonnel", {
    method: 'POST',
    headers: {
      'id':id,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  
  });
  const personnel = await response.json();
  return personnel;
});

export const personnelDeleted = createAsyncThunk("personnel/deletePersonnel", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "personnel/deletePersonnel/"+action.id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
  });
  const personnel = await response.json();
  return personnel;
});

export const personnelChangeEtat = createAsyncThunk("personnel/changeEtat", async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "personnel/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const personnel = await response.json();
    return personnel;
  }
);



