import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
/* import "./assets/css/animate.min.css"; */
/* import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0"; */
import "./assets/scss/style.scss?v=2.0.0";
import "./assets/css/style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import store from "./store";
import { getSettings } from "./Redux/settingsReduce";
import LoginPage from "./views/Settings/User/LoginPage";
import ResetPassword from "./views/Settings/User/ResetPassword";
import jwt_decode from "jwt-decode";
import { getDetailUser } from "./Redux/usersReduce";
import RootBase from "./RootBase";
/* import jwt from "jsonwebtoken"; */

console.log("ssss");

const root = ReactDOM.createRoot(document.getElementById("root"));
var token = null;
var hrefURL = null;
token = localStorage.getItem("x-access-token");
var testLogin = 0;

var i = 0; 
window.addEventListener("offline", function (e) {
  if (i == 0) {
    i = 1;
    this.window.location.reload();
  }
});
window.addEventListener("online", function (e) {
  if (i == 0) {
    i = 1;
    this.window.location.reload();
  }
});


try {
  store.dispatch(getSettings(1));
} catch (error) {
  console.log("settings", error);
}
var id = 0;
if (token !== null) {
  try {
    var decoded = jwt_decode(token);
    /* console.log(decoded) */
    /* const decoded = jwt.verify(token, "mySecretKeyabs"); */
    testLogin = 1;
    /* console.log(decoded) */
    if (decoded.id) {
      id = decoded.id;
      store.dispatch(getDetailUser(decoded.id));
    } else {
      localStorage.clear();
      window.location.replace("/login");
    }
  } catch (err) {
    /* localStorage.clear();
    window.location.replace("/login"); */
    console.log("eee", err);
  }
}
if (hrefURL === "/login") {
  document.title = "login";
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {testLogin === 0 ? (
          <Switch>
            <Route path="/login" render={() => <LoginPage />} key={"1"} />{" "}
            <Route
              path="/resetPassword/:code"
              render={() => <ResetPassword />}
            />
            {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
            <Redirect from="/" to="/login" />
          </Switch>
        ) : (
          <RootBase id={id} />
        )}
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
