import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchLine = createAsyncThunk("line/fetchLine", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "line/allLine", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
  const line = await response.json();
  return line;
});

export const getActiveLine = createAsyncThunk("line/getActiveLine", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "line/getActiveLine", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
  const line = await response.json();
  return line;
});


export const lineAdded = createAsyncThunk("line/addLine", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "line/addLine", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(action),
  });
  const line = await response.json();
  return line;
});

export const lineChangeEtat = createAsyncThunk(
  "line/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "line/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const line = await response.json();
    return line;
  }
);

export const lineGetById = createAsyncThunk("line/lineGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "line/getLine", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const line = await response.json();
    return line;
  });
  

  export const lineGetByIdUser = createAsyncThunk("line/lineGetByIdUser", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "line/getLineByIdUser", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const line = await response.json();
    return line;
  });