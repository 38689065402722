//User
import Profile from "./views/Profile";
import AjouterUser from "./views/Settings/User/AjouterUser";
import ListUser from "./views/Settings/User/ListUser";

//Role
import ListRole from "./views/Settings/Role/ListRole";
import AjouterRole from "./views/Settings/Role/AjouterRole";

//Ligne

import NotFound from "./views/NotFound";
import Settings from "./views/Settings";

//RootBase
import ListRootBase from "./views/Settings/RootBase/ListRootBase";
import AjouterRootBase from "./views/Settings/RootBase/AjouterRootBase";

//Annee
import AjouterAnnee from "./views/Settings/Annee/AjouterAnnee";
import ListAnnee from "./views/Settings/Annee/ListAnnee";

//Brick
import ListBrick from "./views/Settings/Brick/ListBrick";
import AjouterBrick from "./views/Settings/Brick/AjouterBrick";

//Specialite
import ListSpecialite from "./views/Settings/Specialite/ListSpecialite";
import AjouterSpecialite from "./views/Settings/Specialite/AjouterSpecialite";

//Gouvernorat
import ListGouvernorat from "./views/Settings/Gouvernorat/ListGouvernorat";
import AjouterGouvernorat from "./views/Settings/Gouvernorat/AjouterGouvernorat";

//Personnel
import ListPersonnel from "./views/Settings/Personnel/ListPersonnel";
import AjouterPersonnel from "./views/Settings/Personnel/AjouterPersonnel";

//Line
import ListLine from "./views/Settings/Line/ListLine";
import AjouterLine from "./views/Settings/Line/AjouterLine";

//Line Business
import ListLineBusiness from "./views/Settings/LineBusiness/ListLineBusiness";
import AjouterLineBusiness from "./views/Settings/LineBusiness/AjouterLineBusiness";

//Produit
import ListProduit from "./views/Settings/Produit/ListProduit";
import AjouterProduit from "./views/Settings/Produit/AjouterProduit";

//Produit Concurent
import ListProduitConcurent from "./views/Settings/ProduitConcurent/ListProduitConcurent";
import AjouterProduitConcurent from "./views/Settings/ProduitConcurent/AjouterProduitConcurent";

//Constentement
import ListConsentement from "./views/Settings/Consentement/ListConsentement";
import AjouterConsentement from "./views/Settings/Consentement/AjouterConsentement";
import SuiviConsentement from "./views/Settings/Consentement/SuiviConsentement";

//Axes
import ListAxe from "./views/Settings/Axe/ListAxe";
import AjouterAxe from "./views/Settings/Axe/AjouterAxe";

//Reference
import ListReference from "./views/Settings/Reference/ListReference";
import AjouterReference from "./views/Settings/Reference/AjouterReference";

//Grille
import ListGrille from "./views/Settings/Grille/ListGrille";
import AjouterGrille from "./views/Settings/Grille/AjouterGrille";

//Coaching
import Coaching from "./views/Settings/User/Coaching";
import Evaluation from "./views/Settings/EvaluationVad/Evaluation";
import DetailEvaluation from "./views/Settings/Evaluation/DetailEvaluation";
import DetailEval from "./views/Settings/Evaluation/DetailEval";
import DetailEvalVad from "./views/Settings/EvaluationVad/DetailEvalVad";
import Ims from "./views/Settings/User/ims";
import EvaluationSemestrielle from "./views/Settings/Evaluation/EvaluationTrimestrielle ";
import CoachingFlm from "./views/Settings/User/CoachingFlm";

//VADS
import ListVad from "./views/Settings/Vad/ListVad";
import AjouterVad from "./views/Settings/Vad/AjouterVad";
import Vad from "./views/Settings/EvaluationVad/Vad";
import UpdateDate from "./views/Settings/EvaluationVad/UpdateDate";

//Plan
import AjouterPlan from "./views/Settings/Plan/AjouterPlan";
import ListPlan from "./views/Settings/Plan/ListPlan";
import UpdatePlan from "./views/Settings/Plan/UpdatePlan";
import SuiviPlan from "./views/Settings/Plan/SuiviPlan";
import DetailPlan from "./views/Settings/Plan/DetailPlan";

//Iframes
import ListIframe from "./views/Settings/Iframe/ListIframe";
import AjouterIframe from "./views/Settings/Iframe/AjouterIframe";
import Dashboard from "./views/Settings/Iframe/Dashboard";

//PlanCaching
import AjouterPlanCoaching from "./views/Settings/PlanCoaching/AjouterPlanCoaching";
import ListPlanCoaching from "./views/Settings/PlanCoaching/ListPlanCoaching";
import ListPlanCoachingFlm from "./views/Settings/PlanCoachingFlm/ListPlanCoachingFlm";
import AjouterPlanCoachingFlm from "./views/Settings/PlanCoachingFlm/AjouterPlanCoachingFlm";
import DetailPlanCoachingFlm from "./views/Settings/PlanCoachingFlm/DetailPlanCoachingFlm";

//Coach
import AjouterCoach from "./views/Settings/Coach/AjouterCoach";
import ListCoach from "./views/Settings/Coach/ListCoach";
import CoachDuCoach from "./views/Settings/CoachFlm/CoachDuCoach";
import ListCoachDuCoach from "./views/Settings/CoachFlm/ListCoachDuCoach";
import DetailCoachDuCoach from "./views/Settings/CoachFlm/DetailCoachDuCoach";

//IMS DATA
import ListImsData from "./views/Settings/ImsData/ListImsData";
import InsertImsData from "./views/Settings/ImsData/InsertData";
import DetailsImsData from "./views/Settings/ImsData/DetailsImsData";

//Marche
import ListMarche from "./views/Settings/Marche/ListMarche";
import AjouterMarche from "./views/Settings/Marche/AjouterMarche";

//Cycle
import ListCycle from "./views/Settings/Cycle/ListCycle";
import AjouterCycle from "./views/Settings/Cycle/AjouterCycle";


//oce
import InsertOceData from "./views/Settings/OceData/InsertOceData";
import ListOceData from "./views/Settings/OceData/ListOceData";
import DetailsOceData from "./views/Settings/OceData/DetailsOceData";

//plt
import ListPltData from "./views/Settings/PltData/ListPltData";
import InsertPltData from "./views/Settings/PltData/InsertPltData";
import DetailsPltData from "./views/Settings/PltData/DetailsPltData";




//ProduitKey

const Components = {
  ListUser,
  AjouterUser,
  Profile,
  ListRole,
  AjouterRole,
  NotFound,
  Settings,
  ListRootBase,
  AjouterRootBase,
  AjouterAnnee,
  ListAnnee,
  ListBrick,
  AjouterBrick,
  ListSpecialite,
  AjouterSpecialite,
  ListGouvernorat,
  AjouterGouvernorat,
  ListPersonnel,
  AjouterPersonnel,
  ListLine,
  AjouterLine,
  ListLineBusiness,
  AjouterLineBusiness,
  ListProduit,
  AjouterProduit,
  ListProduitConcurent,
  AjouterProduitConcurent,
  ListConsentement,
  AjouterConsentement,
  ListAxe,
  AjouterAxe,
  ListGrille,
  AjouterGrille,
  Coaching,
  Evaluation,
  DetailEvaluation,
  DetailEval,
  ListVad,
  AjouterVad,
  Vad,
  DetailEvalVad,
  Ims,
  ListReference,
  AjouterReference,
  AjouterPlan,
  ListPlan,
  UpdatePlan,
  SuiviPlan,
  DetailPlan,
  ListIframe,
  AjouterIframe,
  Dashboard,
  SuiviConsentement,
  EvaluationSemestrielle,
  AjouterPlanCoaching,
  ListPlanCoaching,
  AjouterCoach,
  ListCoach,
  CoachingFlm,
  CoachDuCoach,
  ListCoachDuCoach,
  DetailCoachDuCoach,
  ListPlanCoachingFlm,
  AjouterPlanCoachingFlm,
  DetailPlanCoachingFlm,
  ListImsData,
  InsertImsData,
  DetailsImsData,
  ListMarche,
  AjouterMarche,
  ListCycle,
  AjouterCycle,
  UpdateDate,
  InsertOceData,
  ListOceData,
  ListPltData,
  InsertPltData,
  DetailsOceData,
  DetailsPltData,
  
};
export default Components;
