import { Button, Card, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialReactTable from "material-react-table";
import { useHistory } from "react-router";
import { fetchImsData, imsDeleted } from "../../../Redux/imsDataReduce";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
function ListImsData() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [entities, setEntities] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const anneeObj = JSON.parse(localStorage.getItem("annee"));
  var annee = anneeObj.label;
  const columns = useMemo(
    () => [
      //column definitions...
      {
        header: "Fichier",
        accessorKey: "nom_file",
      },
      {
        header: "Type",
        accessorKey: "nom_file",
        Cell: ({ cell }) =>
          cell.row.original.type == 1
            ? "Ventes IMS"
            : cell.row.original.type == 2
            ? "Deploiement"
            : cell.row.original.type == 3
            ? "Produit-Line"
            : cell.row.original.type == 4
            ? "IMS Nationals"
            : "Line Données régionales",
      },
      {
        header: "Année",
        accessorKey: "annee",
        Cell: ({ cell }) =>
          cell.row.original.annees_ims == null
            ? ""
            : cell.row.original.annees_ims.annee,
      },
      {
        header: "Mois",
        accessorKey: "mois",
        Cell: ({ cell }) =>
          cell.row.original.mois === 0 ? "" : cell.row.original.mois,
      },
      {
        header: "Date d'insertion",
        accessorKey: "date",
        Cell: ({ cell }) => cell.row.original.createdAt,
      },
      {
        accessorKey: "details",
        header: "Détails",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              className="btn-wd  mr-1 float-left"
              type="button"
              variant="info"
              onClick={() => {
                navigate.push("/imsData/details/" + cell.row.original.id);
              }}
            >
              Détails
            </Button>
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Actions",
        Cell: ({ cell, row }) => (
          <div className="actions-right block_action">
            <Button
              id={"idLigne_" + cell.row.original.id}
              onClick={(e) => {
                confirmMessage(cell.row.original.id, e);
              }}
              variant="danger"
              size="sm"
              className="text-danger btn-link delete"
            >
              <i
                className="fa fa-trash"
                id={"idLigne_" + cell.row.original.id}
              />
            </Button>
          </div>
        ),
      },
      //end
    ],
    []
  );
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };

  const confirmMessage = (id, e) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes-vous sûr de vouloir supprimer ces données IMS ? "
        onConfirm={() => deletePP(id, e)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      />
    );
  };

  function deletePP(id, e) {
    dispatch(imsDeleted({ id })).then((val) => {
      notify(1, "Données supprimées avec succès");
      getIms();
      hideAlert();
    });
  }
  const getIms = useCallback(async () => {
    var brick = await dispatch(fetchImsData({ annee }));
    var res = await brick.payload;
    setEntities(res);
    setLoader(false);
  }, [dispatch]);

  function hideAlert() {
    setAlert(null);
  }

  useEffect(() => {
    getIms();
  }, []);

  function ListTable({ list }) {
    return (
      <MaterialReactTable
        columns={columns}
        data={list}
        enableColumnActions={true}
        enableColumnFilters={true}
        enablePagination={true}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        muiTableBodyRowProps={{ hover: false }}
        state={{ isLoading: loader }}
      />
    );
  }

  return (
    <>
      {alert}
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col md="12">
            <Button
              id="saveAdd"
              className="btn-wd  mr-1 float-left"
              type="button"
              variant="info"
              onClick={() => {
                navigate.push("insertionIms");
              }}
            >
              <span className="btn-label">
                <i className="fas fa-plus"></i>
              </span>
              Ajouter données IMS
            </Button>
          </Col>
          <Col md="12">
            <h4 className="title">Liste des données IMS</h4>
            <ListTable list={entities}></ListTable>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListImsData;
