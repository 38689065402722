import { createAsyncThunk} from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");

export const fetchProduit = createAsyncThunk("produit/fetchProduit", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "produit/allProduit", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
  const produit = await response.json();
  return produit;
});

export const getActiveProduit = createAsyncThunk("produit/getActiveProduit", async () => {
  const response = await fetch(Configuration.BACK_BASEURL + "produit/getActiveProduit", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
  const produit = await response.json();
  return produit;
});


export const produitAdded = createAsyncThunk("produit/addProduit", async (action) => {
  const response = await fetch(Configuration.BACK_BASEURL + "produit/addProduit", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(action),
  });
  const produit = await response.json();
  return produit;
});

export const produitChangeEtat = createAsyncThunk(
  "produit/changeEtat",
  async (id) => {
    const response = await fetch(
      Configuration.BACK_BASEURL + "produit/changeEtat/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    const produit = await response.json();
    return produit;
  }
);

export const produitGetById = createAsyncThunk("produit/produitGetById", async (id1) => {
    const  id  = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "produit/getProduit", {
      method: 'POST',
      headers: {
        'id':id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    
    });
    const produit = await response.json();
    return produit;
  });
  